import { useState } from "react";
import Input from "./Input";
import { isEmpty, checkPhoneNumber, checkEmail } from "../../../validation";
export default function ContactForm() {
  const [values, setValues] = useState({
    name: "",
    email: "",
    mobile: "",
    subject: "",
    message: "",
  });
  const [fieldError, setFieldError] = useState({
    name: "",
    email: "",
    mobile: "",
    subject: "",
    message: "",
  });
  const [apiStatus, setApiStatus] = useState({
    status : "",
    message : ""
    });
  const handleContactSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      try {
        const response = await fetch(
          "https://admin.udupi360.com/get-data/custom/v1/send-email",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          }
        );
        let data = {};
        if (!response.ok) {
          data = await response.json();
          setApiStatus((prevObj) => ({
            ...prevObj,
            status : response.status,
            message : data.message
          }));
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        console.error("Error:", error);
        // Handle error (e.g., show an error message)
      }
      setTimeout(() => {
        setApiStatus({
          status : "",
          message : ""
          })
        setValues({
            name: "",
            email: "",
            mobile: "",
            subject: "",
            message: "",
          })
      },5000)
    }
  };
  const handleInputOnChange = (identifier, value) => {
    setValues((prevObj) => ({
      ...prevObj,
      [identifier]: value,
    }));
  };

  const validateForm = () => {
    let errorCount = 0;
    let isValid = true;
    for (let key in values) {
      if (isEmpty(values[key])) {
        setFieldError((prevObj) => ({
          ...prevObj,
          [key]: "This Field is required.!",
        }));
        errorCount++;
      } else {
        if (key === "mobile") {
          if (!checkPhoneNumber(values[key])) {
            setFieldError((prevObj) => ({
              ...prevObj,
              [key]: "Enter valid phone number",
            }));
            return;
          }
        }
        if (key === "email") {
          if (!checkEmail(values[key])) {
            setFieldError((prevObj) => ({
              ...prevObj,
              [key]: "Enter valid Email address",
            }));
            return;
          }
        }
        setFieldError((prevObj) => ({
          ...prevObj,
          [key]: "",
        }));
      }
    }
    if (errorCount > 0) {
      isValid = false;
    }
    return isValid;
  };
  return (
    <form className="contactForm" onSubmit={handleContactSubmit}>
      <div className="row">
        {!isEmpty(apiStatus.status) && (
          <>
            <div className="col-lg-12">
              <div className={`alert ${apiStatus.status === 200 ? "alert-success" : "alert-danger"}`} role="alert">
                {apiStatus.message}
              </div>
            </div>
          </>
        )}

        <div className="col-lg-12">
          <div className="error-block"></div>
        </div>
        <Input
          type="text"
          className="form-control"
          placeholder="Your name*"
          name="fullName"
          onChange={(event) => handleInputOnChange("name", event.target.value)}
          value={values.name}
          hasError={fieldError.name}
        />
        <Input
          type="text"
          className="form-control"
          placeholder="Email*"
          name="email"
          onChange={(event) => handleInputOnChange("email", event.target.value)}
          value={values.email}
          hasError={fieldError.email}
        />
        <Input
          type="text"
          className="form-control"
          placeholder="Phone Number*"
          name="mobile"
          maxLength={10}
          onChange={(event) =>
            handleInputOnChange("mobile", event.target.value)
          }
          value={values.mobile}
          hasError={fieldError.mobile}
        />
        <Input
          type="text"
          className="form-control"
          placeholder="Subject*"
          name="subject"
          onChange={(event) =>
            handleInputOnChange("subject", event.target.value)
          }
          value={values.subject}
          hasError={fieldError.subject}
        />
        <div className="col-lg-12 mb-4">
          <textarea
            className="form-control"
            rows="6"
            placeholder="Your Message*"
            name="message"
            onChange={(event) =>
              handleInputOnChange("message", event.target.value)
            }
            value={values.message}
          ></textarea>
          {!isEmpty(fieldError.message) && (
            <div className="error-block">
              <p>{fieldError.message}</p>
            </div>
          )}
        </div>
        <div className="col-lg-12">
          <button className="formButton">Submit</button>
        </div>
      </div>
    </form>
  );
}
