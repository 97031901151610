import banner1 from "../../../images/banner1.png";
import banner2 from "../../../images/banner2.png";
import banner3 from "../../../images/banner3.png";
const Banner = ({pageData}) => {
 return(
    <>

                <section className="primary-banner blue-bg">
                <div className="">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="banner-img row">
                        <div className="col-lg-4 img-holder p-0">
                          <img src={banner1} alt="" />
                        </div>
                        <div className="col-lg-4 img-holder p-0">
                          <img src={banner2} alt="" />
                        </div>
                        <div className="col-lg-4 img-holder p-0">
                          <img src={banner3} alt="" />
                        </div>
                        <div className="text-holder">
                          <div className="container">
                            <h2>{pageData.acf.header_title}</h2>
                            <p>
                              {pageData.acf.header_description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
    </>

 )
};

export default Banner;