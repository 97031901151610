import categoryBanner from "../../../images/category.jfif";
const CategoryBanner = ({pageData}) => {
return (
    <section className="primary-banner blue-bg">
    <div className="">
      <div className="row">
        <div className="col-lg-12">
          <div className="banner-img row">
            <div className="col-lg-12 img-holder p-0">
              <img src={categoryBanner} alt="" />
            </div>
            <div className="text-holder">
              <div className="container">
                <h2>{pageData.acf.header_title}</h2>
                <p>
                {pageData.acf.header_description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)
}

export default CategoryBanner;