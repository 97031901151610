export async function GetPageDetails(pageRoute) {
  try {
    let routeVal = pageRoute.replace("/", "");
    let pageDetails = {};
    if (routeVal === "") {
      routeVal = "home-page";
    }
    // Fetch the primary menu data
    const menuResponse = await fetch(
      "https://admin.udupi360.com/get-data/custom/v1/menu/primary-menu"
    );
    const menuData = await menuResponse.json();

    // Find the current page based on the route value
    const currentPage = menuData.find(
      (resp) => resp.url.split("/").slice(-2, -1)[0] === routeVal
    );

    if (currentPage && currentPage.object_id) {
      const pageID = currentPage.object_id;

      // Fetch the page details using the page ID
      const pageResponse = await fetch(
        `https://admin.udupi360.com/get-data/wp/v2/pages/${pageID}`
      );
      pageDetails = await pageResponse.json();
    }

    return pageDetails;
  } catch (error) {
    console.error("Error fetching page details:", error);
    return {}; // Return an empty object in case of error
  }
}

export async function GetDefaultPageDetails() {
  const defaultPageID = 156;
  try {
    // Fetch the primary menu data
    const pageResponse = await fetch(
      `https://admin.udupi360.com/get-data/wp/v2/pages/${defaultPageID}`
    );
    const pageResponseData = await pageResponse.json();
    return pageResponseData;
  } catch (error) {
    console.error("Error fetching default page details:", error);
    return {}; // Return an empty object in case of error
  }
}
