import categoryTabImage from '../../../images/service/service1.jpg';
import MyContext from '../../MyContext';
const CategoryTabHeader = (props) => {
return (
    <>
    <MyContext.Consumer>
        {
            CATEGORY_LIST  => {
                return (
                    <ul className="nav nav-tabs d-flex justify-content-center category-page-tab" id="myTab" role="tablist">
                    {CATEGORY_LIST.map((tabItem) => {
                        return (
                            <li className="nav-item" role="presentation" key ={tabItem.id} onClick={() => {props.categoryClick(tabItem.id)}}>
                            <div className={`nav-link ${(props.categoryTab === tabItem.id) ? "active" : ""}`}>
                                <div className="each-tab">
                                    <div className="img-holder">
                                        <div className="ovly"></div>
                                        <img src={categoryTabImage} alt="" />
                                    </div>
                                </div>
                                <span>{tabItem.name}</span>
                            </div>  
                        </li>
                        )
                    })}
            </ul>
                )

            }
        }

            </MyContext.Consumer>
    </>
)
}
export default CategoryTabHeader;