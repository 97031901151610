import { useState, useEffect } from "react";
import BlogCard from "./BlogCard";
import { fetchBlogData } from "../../data/CategoryData";
const BlogtabContent = () => {
  const [blogData, setBlogData] = useState([]);
  useEffect(() => {
    async function getBlogData() {
      const blogData = await fetchBlogData(3);
      setBlogData(blogData);
    }
    getBlogData();
  }, []);
  return (
    <>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          {blogData.length > 0 && (
            <div className="row">
              {blogData.map((blog) => {
                return <BlogCard key={blog.id} blogInfo={blog} />;
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BlogtabContent;
