
import ContactSection from "./ContactSection";
const Contact = () => {

  return (
        <>
          <section class="contactSection bg-white">
            <div class="container">
              <div class="row">
                <ContactSection/>
              </div>
            </div>
          </section>
        </>
  );
};

export default Contact;
