import blogBanner from '../../../images/faq/banner.png';
// import BlogTabHeader from './BlogTabHeader';
import BlogtabContent from './BlogTabContent';
const Blog = () => {
return (
    <>
          <section className="primary-banner blue-bg sectionBanner">
         <div className="">
            <div className="row">
               <div className="col-lg-12">
                  <div className="banner-img row">
                     <div className="col-lg-12 img-holder p-0">
                        <img src={blogBanner} alt="" />
                        <div className="bannerContent">
                              <h5>BLOG</h5>
                              <h3>BLOG</h3>
                              <p>We keep you posted on our journey</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="eachSection faqSection bg-white">
         <div className="container">
            <div className="row">
                <div className="col-lg-12 each-heading">
                  <div className="heading-title">
                     <span>Our Blog</span>
                  </div>
                    <h2>Our Latest News</h2>
                </div>
            </div>
            <div className="row">
               <div className="col-lg-12">
                    {/* <BlogTabHeader blogOnClick={blogTabClick} blogTab={blogtab}/> */}
                    <BlogtabContent />
               </div>
            </div>
         </div>
      </section>
    </>
)
}

export default Blog;