export async function FetchCatgories() {
  let categories = [];
  const response = await fetch(
    "https://admin.udupi360.com/get-data/wp/v2/categories?parent=4"
  );
  categories = await response.json();
  return categories;
}
export async function fetchCategoryData(categoryId) {
  let categoryItems = [];
  const response = await fetch(
    `https://admin.udupi360.com/get-data/wp/v2/posts?categories=${categoryId}&orderby=udupi_360_rating_for_property&order=desc`
  );
  categoryItems = await response.json();
  return categoryItems;
}
export async function fetchBlogData(limit) {
  let blogCategoryID = 1;
  let categoryItems = [];
  const response = await fetch(
    `https://admin.udupi360.com/get-data/wp/v2/posts?categories=${blogCategoryID}&limit=${limit}`
  );
  categoryItems = await response.json();
  return categoryItems;
}
export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};