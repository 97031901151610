import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import mail from "../../images/icons/mail.png";
import phone from "../../images/icons/phone.png";
import fb from "../../images/icons/facebook.png";
import insta from "../../images/icons/instagram.png";
const Footer = ({ data }) => {
  const [footerMenuData, setfooterMenuData] = useState([]);

  useEffect(() => {
    async function fetchFooterMenu() {
      const response = await fetch(
        "https://admin.udupi360.com/get-data/custom/v1/menu/footer-menu"
      );
      const respData = await response.json();
      setfooterMenuData(respData);
    }

    fetchFooterMenu();
  }, []);

  const navigate = useNavigate();

  const handleMenuClick = (event) => {
    event.preventDefault();
    // Navigate to the home page and scroll to Section 2
    navigate("/home-page#faq");
  };

  return (
    <>
      <footer className="footer footer-bg">
        <div className="container-fluid footerHeader">
          <div className="row">
            <div className="col-lg-12">
              <img src={data.acf.site_logo} alt="" />
            </div>
          </div>
        </div>
        <div className="container footerMiddle mt-4">
          <div className="row">
            <div className="col-lg-12 mb-4">
              <p className="footerDesc">{data.acf.footer_text}</p>
            </div>
            <div className="col-lg-12 footerMenu">
              {footerMenuData.length > 0 && (
                <ul>
                  {footerMenuData.map((menu) => {
                    const respUrl = menu.url.split("/");
                    const routeUrl =
                      respUrl.length > 0 ? respUrl[respUrl.length - 2] : "";
                    return (
                      <li key={menu.ID}>
                        <Link to={routeUrl}>{menu.title}</Link>
                      </li>
                    );
                  })}
                  <li>
                    <button onClick={handleMenuClick}>FAQ</button>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="container mt-4 mb-4">
          <div className="row">
            <div className="col-lg-12 footerContact">
              <ul>
                <li className="footerContactItem">
                  <a href={`mailto:${data.acf.footer_email}`}>
                    <img src={mail} alt="" />
                    <div>
                      <h6>Email</h6>
                      <h6> {data.acf.footer_email}</h6>
                    </div>
                  </a>
                </li>
                <li className="footerContactItem">
                  <a href={`tel:${data.acf.footer_number}`}>
                    <img src={phone} alt="" />
                    <div>
                      <h6>Call Us</h6>
                      <h6> {data.acf.footer_number}</h6>
                    </div>
                  </a>
                </li>
                <li className="footerContactItem">
                  <a href={data.acf.footer_facebook}>
                    <img src={fb} alt="" />
                    <div>
                      <h6>Facebook</h6>
                    </div>
                  </a>
                </li>
                <li className="footerContactItem">
                  <a href={data.acf.footer_insta}>
                    <img src={insta} alt="" />
                    <div>
                      <h6>Instagram</h6>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 copyrightDiv mb-2">
              <p>{data.acf.site_copy_right}</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
