import starImage from "../../../images/star.svg";
import { Link } from "react-router-dom";
const CategoryCard = ({ content, currentCategoryID }) => {
  let contentInfo = content.acf;
  return (
    <>
      <Link to={`/Category/${currentCategoryID}/${content.id}`}>
        <div className="each-card">
          <div className="img-holder">
            <img src={contentInfo.featured_image} alt="" />
          </div>
          <div className="img-cnt">
            <h3>{contentInfo.title}</h3>
            <p>{contentInfo.short_content}</p>
            <div className="btn-holder d-flex align-items-center justify-content-between mt-3">
              <span className="d-flex align-items-center">
                <img src={starImage} alt="star" className="me-1" />
                {contentInfo.rating_count}
              </span>

              <button className="primary-button">View Details</button>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default CategoryCard;
