import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
const Navbar = ({ data }) => {
  const [primaryMenu, setPrimarymenu] = useState([]);
  useEffect(() => {
    async function fetchPrimaryMenu() {
      const response = await fetch(
        "https://admin.udupi360.com/get-data/custom/v1/menu/primary-menu"
      );
      const respData = await response.json();
      setPrimarymenu(respData);
    }

    fetchPrimaryMenu();
  }, []);

  const navToggleBtnRef = useRef(null);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark static-top fixed-top">
        <div className="container">
          <Link className="navbar-brand" to="/home-page">
            <img src={data.acf.site_logo} alt="..." className="logo" />
          </Link>
          <button
            ref={navToggleBtnRef}
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* <Router> */}
            {primaryMenu.length > 0 && (
              <ul className="navbar-nav ms-auto">
                {primaryMenu.map((menu) => {
                  const respUrl = menu.url.split("/");
                  const routeUrl =
                    respUrl.length > 0 ? respUrl[respUrl.length - 2] : "";
                  return (
                    <li className="nav-item" key={menu.ID}>
                      <Link
                        className={`nav-link ${
                          window.location.pathname === routeUrl ? "active" : ""
                        }`}
                        to={routeUrl}
                        onClick={() => {
                          navToggleBtnRef.current.click();
                        }}
                      >
                        {menu.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}

            {/* </Router> */}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
