import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BlogCard from "./BlogCard";
import CategoryCard from "../Category/CategoryCard";
import BlogShare from "./BlogShare";
import Map from "../Map";
import {
  fetchBlogData,
  isEmptyObject,
  fetchCategoryData,
} from "../../data/CategoryData";
let catId = "";
const BlogDetails = () => {
  const [data, setData] = useState([]);
  const [dataContent, setDataContent] = useState({});
  const [cardType,setCardType] = useState('');
  
  useEffect(() => {
    async function getBlogData() {
      if (window.location.pathname.split("/")[1] === "Blog") {
        const blogData = await fetchBlogData(3);
        setData(blogData);
        setCardType("Blog");
      } else {
        catId = window.location.pathname.split("/")[2]
        const catData = await fetchCategoryData(catId);
        setData(catData);
        setCardType("Category");
      }
    }
    getBlogData();
    let currentBlogId = "";
    if (window.location.pathname.split("/")[1] === "Blog") {
      currentBlogId = window.location.pathname.split("/").splice(2, 1)[0];
    } else {
      currentBlogId = window.location.pathname.split("/").splice(2, 2)[1];
    }
    let Details = data.filter(
      (d) => parseInt(d.id) === parseInt(currentBlogId)
    );
    if (Details.length > 0) {
      setDataContent(Details[0]);
    }
  }, [data]);
  return (
    <>
      {!isEmptyObject(dataContent) && (
        <>
          <section className="primary-banner blue-bg">
            <div className="">
              <div className="row">
                <div className="col-lg-12">
                  <div className="banner-img row">
                    <div className="col-lg-12 img-holder p-0">
                      <img src={dataContent.acf.featured_image} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="blogsDetailsSection eachSection bg-white">
            <div className="container">
              <div className="row blogHeaderDiv">
                <div className="col-lg-12">
                  <h2 className="blogHeader">{dataContent.acf.title}</h2>
                  {/* <p className="dataContent">Nestled in the heart of [Location], [Hotel Name] stands as a beacon of luxury and refinement, offering discerning travelers an unparalleled experience of comfort, hospitality, and gastronomic delight. Join us on a tantalizing journey as we explore the culinary wonders and lavish accommodations that make [Hotel Name] a destination unto itself.</p> */}
                </div>
              </div>
              <div className="row blogContentDiv">
                <div className="col-lg-6 blogContentDiv1">
                  <div className="row">
                    <div className="col-lg-12">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: dataContent.acf.content,
                        }}
                      />
                    </div>
                  </div>
                  {cardType === "Blog" && <>
                    <div className="row">
                    <div className="col-lg-12">
                      <div className="authorDiv">
                        <img src="images/blog-1/author.png" alt="" />
                        <div className="authorDetails">
                          <h5>{dataContent.acf.author_name}</h5>
                          <h6>{dataContent.acf.blog_publish_date}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  </>
                  }
                 
                </div>
                <div className="col-lg-4 offset-lg-2 blogContentDiv2">
                  <div className="row">
                    <div className="col-lg-12 bg-light blogHelpSection mb-4">
                      <h4 className="blogContentDiv2Header">
                        {dataContent.acf.get_in_touch_section_title}
                      </h4>
                      <p className="blogContentDiv2Desc">
                        {dataContent.acf.get_in_touch_section_description}
                      </p>
                      <Link to='/contact-page'>
                      <button
                        type="button"
                        className="btn btn-warning blogContentDiv2Btn"
                      >
                        Contact
                      </button>
                      </Link>
                      
                    </div>
                    <div className="col-lg-12 blogGetInTouch mb-4">
                      <h4 className="blogGetInTouchHeader">Get In Touch</h4>
                      <div className="blogGetInTouchDiv mt-4 mb-4">
                        <h5>Need help?</h5>
                        <h6><a href={`tel:${dataContent.acf.contact_number}`}>{dataContent.acf.contact_number}</a></h6>
                      </div>
                      <hr />
                      <div className="blogGetInTouchDiv mt-4 mb-2">
                        <h5>Email</h5>
                        <h6><a href={`mailto:${dataContent.acf.email_id}`}>{dataContent.acf.email_id}</a></h6>
                      </div>
                    </div>
                    <div className="col-lg-12 mapDiv">
                      {dataContent.acf.hasOwnProperty("google_map_link") && (
                        <Map embedded={dataContent.acf.google_map_link} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {data.length > 0 && (
            <section className="relatedBlogsSection">
              <div className="container">
                <div className="row ">
                  <div className="col-lg-12 mb-4">
                    <h2 className="leftHeading">
                    {cardType === "Blog" && "Other Blogs You Might Be Interested In" }
                    {cardType === "Category" && "Related properties" }
                    </h2>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      {cardType === "Blog" && <>
                        {data.map((blog) => {
                          return <BlogCard key={blog.id} blogInfo={blog} />;
                        })}
                      </>
                      }
                     {cardType === "Category" && <>
                        {data.map((cat) => {
                         return (
                          <div className="col-lg-4 col-md-6 mb-4 best-service" key={cat.id}>
                            <CategoryCard content={cat} currentCategoryID = {catId} />
                          </div>
                        );
                        })}
                      </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          <BlogShare blogShareInfo={dataContent.acf} />
        </>
      )}
    </>
  );
};

export default BlogDetails;
