import { useState } from 'react';
import faqBanner from '../../../images/faq/banner.png';
import FaqTabHeader from './FaqTabHeader';
import FaqTabContent from './FaqTabContent';
const Faq = () => {
    const [faqTab,setFaqTab] = useState('All');
    const blogTabClick = (faqType) => {
        setFaqTab(() => {
            return faqType;
        })
    }
return(
    <>
     <section className="primary-banner blue-bg sectionBanner">
         <div className="">
            <div className="row">
               <div className="col-lg-12">
                  <div className="banner-img row">
                     <div className="col-lg-12 img-holder p-0">
                        <img src={faqBanner} alt="" />
                        <div className="bannerContent">
                              <h5>FAQ</h5>
                              <h3>Frequently Asked Question</h3>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="eachSection faqSection bg-white">
         <div className="container">
            <div className="row">
               <div className="col-lg-12">
                  <FaqTabHeader  blogTabClick={blogTabClick} faqTab={faqTab}/>
                 <FaqTabContent />
               </div>
            </div>
         </div>
      </section>
    </>
)
}

export default Faq;