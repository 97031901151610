import shareImage from '../../../images/icons/share.png';
import fbImage from '../../../images/icons/fb-small.png';
import instaImage from '../../../images/icons/insta-small.png';
import mailImage from '../../../images/icons/mail-small.png';
const BlogShare = ({blogShareInfo}) => {
  const blogUrl = window.location.href; 
  const blogTitle = blogShareInfo.title;
    // Facebook share link
    const fbShareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(blogUrl)}`;

    // Instagram (only option is to copy the link)
    const instagramShareText = `Check out this blog post: ${blogTitle} - ${blogUrl}`;
  
    // Email share link
    const emailShareLink = `mailto:?subject=${encodeURIComponent(blogTitle)}&body=${encodeURIComponent(
      `I found this blog post interesting: ${blogUrl}`
    )}`;
return (
    <>
          <div className="shareDiv">
         <button className="shareBtn" type="button" id="dropdownMenuButton" aria-expanded="false">
          <img src={shareImage} alt="" />
         </button>
         <ul className="shareDivMenu" aria-labelledby="dropdownMenuButton">
           <li><a className="dropdown-item" href={fbShareLink} target="_blank" rel="noopener noreferrer"><img src={fbImage} alt="" /> Facebook</a></li>
           <li><button className="dropdown-item"  onClick={() => {
            navigator.clipboard.writeText(instagramShareText);
            alert('Link copied to clipboard! Open Instagram and paste it to share.');
          }}><img src={instaImage} alt="" /> Instagram</button></li>
           <li><a className="dropdown-item" href={emailShareLink} target="_blank" rel="noopener noreferrer"><img src={mailImage} alt="" /> Email</a></li>
         </ul>
       </div>
    </>
)
}

export default BlogShare;