import blogImageAuthor from "../../../images/blog/author1.png";
import { Link } from "react-router-dom";
const BlogCard = ({ blogInfo }) => {
  let blogData = blogInfo.acf;
  let tags = [];
  if ("tag" in blogData) {
    if (blogData.tags.length > 0) {
      tags = blogData.tags.split(",");
    }
  }
  return (
    <>
      <div className="col-lg-4 col-md-6 mb-4 ">
        <Link to={`/Blog/${blogInfo.id}`}>
          <div className="bg-white blogItem">
            <img src={blogData.featured_image} alt="" className="blogImage" />
            <div className="blogItemContent">
              {tags.length > 0 && (
                <div className="d-flex justify-content-between blogTagDiv">
                  <div className="info-left d-flex">
                    {tags.map((tag, index) => {
                      return (
                        <div className="each-info" key={index}>
                          {tag}
                        </div>
                      );
                    })}
                  </div>
                  {/* <div className="info-right">2 Min read</div> */}
                </div>
              )}

              <div className="more-info">
                <h4>{blogData.title}</h4>
                <p>{blogData.short_content}</p>
                <div className="author-details d-flex">
                  <div className="img-holder">
                    <img src="" alt="" />
                  </div>
                  <div className="authorDiv">
                    <img src={blogImageAuthor} alt="" />
                    <div className="authorDetails">
                      <h5>By {blogData.author_name}</h5>
                      <h6>{blogData.blog_publish_date}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default BlogCard;
