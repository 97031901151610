const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const phoneRegex = /^\+?(\d{1,3})?[-.\s]?(\(?\d{3}\)?)[-.\s]?\d{3}[-.\s]?\d{4}$/;

export function isEmpty(value){
    return value === "";
}

export function checkPhoneNumber(number){
  return  phoneRegex.test(number);
}

export function checkEmail(email){
    return  emailRegex.test(email);
  }