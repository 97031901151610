import MyContext from "../../MyContext";
const TabsHeader = (props) => {
  return (
    <>
      <MyContext.Consumer>
        {(CATEGORY_LIST) => {
          return (
            <ul
              className="nav nav-tabs d-flex justify-content-center"
              id="myTab"
            >
              {CATEGORY_LIST.map((tabItem) => {
                return (
                  <li className="nav-item" key={tabItem.id}>
                    <button
                      className={`nav-link ${
                        props.categoryState === tabItem.id ? "active" : ""
                      }`}
                      type="button"
                      onClick={() => {
                        props.TabOnclick(tabItem.id);
                      }}
                    >
                      {tabItem.name}
                    </button>
                  </li>
                );
              })}
            </ul>
          );
        }}
      </MyContext.Consumer>
    </>
  );
};

export default TabsHeader;
