import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Banner from "./Banner";
import faqResource from "../../../images/icons/phone-2.png";
import TabsHeader from "./TabsHeader";
import TabsContent from "./TabsContent";
import BlogCard from "../Blog/BlogCard";
import FaqSection from "../Faq/FaqSection";
import ContactSection from "../Contact/ContactSection";
import { GetPageDetails } from "../../data/PageDetails";
import { isEmptyObject, fetchBlogData } from "../../data/CategoryData";
const Home = ({ categoryList }) => {
  const location = useLocation();
  // useEffect(() => {

  // }, [location.hash]);

  const [pageData, setPageData] = useState({});
  const [faqData, setFaqData] = useState([]);
  const [blogData, setBlogData] = useState([]);
  useEffect(() => {
    async function pageInfo() {
      const PAGE_DATA = await GetPageDetails(window.location.pathname);
      if (JSON.stringify(PAGE_DATA) !== JSON.stringify(pageData)) {
        setPageData(PAGE_DATA);
      }
      if (!isEmptyObject(pageData)) {
        let faqList = pageData.acf.faq_section;
        const faqArray = [];
        for (let i = 1; i <= Object.keys(faqList).length / 2; i++) {
          faqArray.push({
            question: faqList[`question_${i}`],
            answer: faqList[`answer_${i}`],
          });
        }
        setFaqData(faqArray);
      }
    }
    pageInfo();
  }, [pageData]);
  useEffect(() => {
    async function getBlogData() {
      const blogs = await fetchBlogData(3);
      setBlogData(blogs);
    }
    getBlogData();
  }, []);
  let currentCategory = categoryList.length > 0 ? categoryList[0].id : "";
  const [categoryState, setCategoryState] = useState(currentCategory);
  const categoryTabClick = (tabItem) => {
    setCategoryState(() => {
      return tabItem;
    });
  };

  // const isFaqSection = location.hash === '#faq';
  // useEffect(() => {
  if (location.hash === "#faq") {
    const element = document.querySelector(location.hash);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      window.history.replaceState(null, null, " ");
    }
  }
  // },[])

  return (
    <>
      {!isEmptyObject(pageData) && (
        <>
          <Banner pageData={pageData} />
          <section className="eachSection best-service">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 each-heading">
                  <div className="heading-title">
                    <span>Category</span>
                  </div>
                  <h2>{pageData.acf.category_section_title}</h2>
                </div>
              </div>
              <TabsHeader
                TabOnclick={categoryTabClick}
                categoryState={categoryState}
              />
              <TabsContent categoryTab={categoryState} />
            </div>
          </section>

          <section className="eachSection latest-news secondary-bg">
            <div className="container">
              <div className="row about-cnt ">
                <div className="col-lg-12 each-heading">
                  <div className="heading-title">
                    <span>Our Blog</span>
                  </div>
                  <h2>{pageData.acf.blog_section_title}</h2>
                </div>
                {blogData.length > 0 && (
                  <div className="col-lg-12">
                    <div className="row">
                      {blogData.map((blog) => {
                        return <BlogCard key={blog.id} blogInfo={blog} />;
                      })}
                      {/* <BlogCard />
                      <BlogCard /> */}
                    </div>
                    <div className="d-flex justify-content-center">
                      <Link to="/blog" className="secondary-button">
                        More Blog
                      </Link>
                    </div>
                  </div>
                )}
                {blogData.length === 0 && (
                  <div className="col-lg-12">
                    <div className="row">
                      <p className="text-center">No Data</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>

          <section className="eachSection faq" id="faq">
            <div className="container">
              <div className="row about-cnt ">
                <div className="col-md-6 faq-cnt">
                  <div className="container">
                    <div className="each-heading text-start">
                      <div className="heading-title">
                        <span>FAQ</span>
                      </div>
                      <h2>{pageData.acf.faq_section_title}</h2>
                    </div>
                  </div>
                  <div className="faqSection">
                    <div className="tab-content">
                      <FaqSection faqs={faqData} />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="faq-bg-section">
                    <img
                      src="images/faq/faq-bg.jfif"
                      alt=""
                      className="faq-bg"
                    />
                    <div className="col-md-8 contactFormSection p-5">
                      <div className="row">
                        <div className="col-lg-12 contactHeader text-start">
                          <h6>Let’s Talk</h6>
                          <h4>{pageData.acf.faq_contact_section_title}</h4>
                        </div>
                        <div className="col-lg-12 contactInfo flex-column align-items-start justify-content-start my-2">
                          <ul className="d-block">
                            <li>
                              <Link className="d-flex align-items-center text-start flex-row">
                                <img src={faqResource} alt="" />
                                <h6 className="ms-2">
                                  {" "}
                                  {
                                    pageData.acf.faq_contact_section_title_2
                                  }{" "}
                                  <br />
                                  {pageData.acf.faq_contact_number}
                                </h6>
                              </Link>
                            </li>
                          </ul>
                          <Link to="/contact-page">
                          <button className="primary-button mt-2">
                            Contact Us
                          </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ContactSection />
        </>
      )}
    </>
  );
};

export default Home;
