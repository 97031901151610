import TabsContent from "../Home/TabsContent";
import CategoryTabHeader from "./CategoryTabHeader";
import { useState, useEffect } from "react";
import { GetPageDetails } from "../../data/PageDetails";
import { isEmptyObject } from "../../data/CategoryData";
import CategoryBanner from "./CategoryBanner";
const Category = ({ categoryList }) => {
  const [pageData, setPageData] = useState({});
  useEffect(() => {
    async function pageInfo() {
      const PAGE_DATA = await GetPageDetails(window.location.pathname);
      setPageData(PAGE_DATA);
    }
    pageInfo();
  }, []);
  let currentCategory = categoryList.length > 0 ? categoryList[0].id : "";
  const [categoryTab, setCategoryTab] = useState(currentCategory);
  const updateCategoryTab = (category) => {
    setCategoryTab(() => {
      return category;
    });
  };
  return (
    <>
      {!isEmptyObject(pageData) && (
        <>
          <CategoryBanner pageData={pageData} />
          <section className="eachSection best-service">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 each-heading">
                  <div className="heading-title">
                    <span>Category</span>
                  </div>
                  <h2>We Offer Best Service</h2>
                </div>
              </div>
              <CategoryTabHeader
                categoryClick={updateCategoryTab}
                categoryTab={categoryTab}
              />
              <TabsContent categoryTab={categoryTab} />
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Category;
