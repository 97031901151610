import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CategoryCard from "../Category/CategoryCard";
import { fetchCategoryData } from "../../data/CategoryData";
const TabsContent = (props) => {
  let categoryId = props.categoryTab;
  const [contentList, setContentList] = useState([]);
  useEffect(() => {
    async function getCatoryData() {
      const categoryData = await fetchCategoryData(categoryId);
      setContentList(categoryData);
    }
    getCatoryData();
  }, [categoryId]);
  return (
    <>
      {contentList.length === 0 && <p className="text-center">No Data</p>}
      {contentList.length > 0 && (
        <>
          <div className="tab-content">
            <div className="tab-pane active">
              <div className=" row each-cnt">
                {contentList.map((content) => {
                  return (
                    <div className="col-lg-4 col-md-6 mb-4" key={content.id}>
                      <CategoryCard
                        content={content}
                        currentCategoryID={categoryId}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {(window.location.pathname === "/" ||
            window.location.pathname === "/home-page") && (
            <div className="btn-holder d-flex justify-content-center mt-3">
              <Link to="/category" className="secondary-button">
                More Category
              </Link>
              {/* <button className="secondary-button">More Category</button> */}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TabsContent;
