import React from "react";
import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import "./media.css";
import Navbar from "./components/particles/Navbar";
import Footer from "./components/particles/Footer";
import Home from "./components/pages/Home/Home";
import Category from "./components/pages/Category/Category";
import Blog from "./components/pages/Blog/Blog";
import Contact from "./components/pages/Contact/Contact";
import Faq from "./components/pages/Faq/Faq";
import MyContext from "./components/MyContext";
import { FetchCatgories } from "./components/data/CategoryData";
import BlogDetails from "./components/pages/Blog/Blog-Details";
import ScrollToTop from "./components/ScrollTop";
import Loader from "./components/particles/Loader";
import { GetDefaultPageDetails } from "./components/data/PageDetails";
import { isEmptyObject } from "./components/data/CategoryData";
function App() {
  const [catgories, setCategory] = useState([]);
  const [defaultInfo, setDefualtInfo] = useState({});
  useEffect(() => {
    async function getCatory() {
      const category = await FetchCatgories();
      setCategory(category);
    }
    getCatory();
  }, []);

  useEffect(() => {
    async function getDefaultData() {
      const info = await GetDefaultPageDetails();
      setDefualtInfo(info);
    }
    getDefaultData();
  }, []);

  return (
    <div className="App">
      {catgories.length === 0 ? (
        <Loader />
      ) : (
        <MyContext.Provider value={catgories}>
          <ScrollToTop />
          {!isEmptyObject(defaultInfo) && <Navbar data={defaultInfo} />}
          <Routes>
            <Route exact path="/" element={<Home categoryList={catgories} />} />
            <Route
              exact
              path="/home-page"
              element={<Home categoryList={catgories} />}
            />
            <Route
              exact
              path="/category"
              element={<Category categoryList={catgories} />}
            />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/contact-page" element={<Contact />} />
            <Route exact path="/Faq" element={<Faq />} />
            <Route exact path="/Blog/*" element={<BlogDetails />} />
            <Route exact path="/Category/*" element={<BlogDetails />} />
          </Routes>
          {!isEmptyObject(defaultInfo) && <Footer data={defaultInfo} />}
        </MyContext.Provider>
      )}
    </div>
  );
}

export default App;
