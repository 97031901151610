const FaqSection = ({faqs}) => {
return (
    <>
                      <div className="accordion" id="accordionExample">
                      {faqs.length > 0 && faqs.map((faq, index) => {
                                                        return(
                                                          <div className="accordion-item" key={index}>
                                                          <h2 className="accordion-header">
                                                            <button
                                                              className="accordion-button collapsed"
                                                              type="button"
                                                              data-bs-toggle="collapse"
                                                              data-bs-target={`#collapse${index}`}
                                                              aria-expanded="false"
                                                              aria-controls="collapseTwo"
                                                            >
                                                             {faq.question}
                                                            </button>
                                                          </h2>
                                                          <div
                                                            id={`collapse${index}`}
                                                            className="accordion-collapse collapse"
                                                            data-bs-parent="#accordionExample"
                                                          >
                                                            <div className="accordion-body">
                                                              <p>
                                                              {faq.answer}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        )
                                                    }
                        )
                        }
                  </div>
    </>
)
}

export default FaqSection;