const FaqTabContent = () => {
return (
    <>
     <div className="tab-content" id="pills-tabContent">
                       <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className="accordion" id="accordionExample">
                           <div className="accordion-item">
                             <h2 className="accordion-header">
                               <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                 How can i contact your team?
                               </button>
                             </h2>
                             <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                               <div className="accordion-body">
                                 <p>You can reach us by filling out the contact form on our website’s “Contact Us” page. Alternatively, you can email us at contactus@udupi360.com or call at 9876543210</p>
                               </div>
                             </div>
                           </div>
                           <div className="accordion-item">
                             <h2 className="accordion-header">
                               <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                 What are your business hours?
                               </button>
                             </h2>
                             <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                               <div className="accordion-body">
                                 <p>You can reach us by filling out the contact form on our website’s “Contact Us” page. Alternatively, you can email us at contactus@udupi360.com or call at 9876543210</p>
                               </div>
                             </div>
                           </div>
                           <div className="accordion-item">
                             <h2 className="accordion-header">
                               <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                 Do you provide information on temple visits and tours?
                               </button>
                             </h2>
                             <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                               <div className="accordion-body">
                                 <p>You can reach us by filling out the contact form on our website’s “Contact Us” page. Alternatively, you can email us at contactus@udupi360.com or call at 9876543210</p>
                               </div>
                             </div>
                           </div>
                           <div className="accordion-item">
                              <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                 Can i request specific information about a particular temple?
                                </button>
                              </h2>
                              <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  <p>You can reach us by filling out the contact form on our website’s “Contact Us” page. Alternatively, you can email us at contactus@udupi360.com or call at 9876543210</p>
                                </div>
                              </div>
                            </div>
                         </div>
                       </div>
                     </div>
    </>
)
}

export default FaqTabContent;