import { useState, useEffect } from "react";
import mailIcon from "../../../images/icons/mail-2.png";
import phoneIcon from "../../../images/icons/phone-2.png";
import locationIcon from "../../../images/icons/location.png";
import { GetPageDetails } from "../../data/PageDetails";
import { isEmptyObject } from "../../data/CategoryData";
import ContactForm from "./ContactForm";
const ContactSection = () => {
  const [contactPageData, setContactPageData] = useState({});
  useEffect(() => {
    async function pageInfo() {
      const PAGE_DATA = await GetPageDetails("/contact-page");
      setContactPageData(PAGE_DATA);
    }
    pageInfo();
  }, []);
  return (
    <>
      {!isEmptyObject(contactPageData) && (
        <>
          <section className="col-lg-12 contactFormSection">
            <div className="col-md-8 offset-md-2 ">
              <div className="row">
                <div className="col-lg-12 contactHeader">
                  <h6>Contact</h6>
                  <h3>{contactPageData.acf.form_heading}</h3>
                  <p className="text-center">
                    {contactPageData.acf.form_description}
                  </p>
                </div>
                <div className="col-lg-12 contactInfo">
                  <ul>
                    <li>
                      <a href={`mailto:${contactPageData.acf.email}`}>
                        <img src={mailIcon} alt="" />
                        <h6> {contactPageData.acf.email}</h6>
                      </a>
                    </li>
                    <li>
                      <a href={`tel:${contactPageData.acf.mobile}`}>
                        <img src={phoneIcon} alt="" />
                        <h6> {contactPageData.acf.mobile}</h6>
                      </a>
                    </li>
                    <li>
                      <button>
                        <img src={locationIcon} alt="" />
                        <h6>{contactPageData.acf.address}</h6>
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-12">
                  <ContactForm />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default ContactSection;
