import { BlogTypes } from "../../data/BlogData";
const FaqTabHeader = (props) => {
  return (
    <>
      <ul className="nav nav-pills mb-4" id="pills-tab" role="tablist">
        {BlogTypes.map((blog, index) => {
          return (
            <li
              className="nav-item"
              role="presentation"
              key={index}
              onClick={() => {
                props.blogTabClick(blog);
              }}
            >
              <button
                className={`nav-link ${props.faqTab === blog ? "active" : ""}`}
                type="button"
              >
                {blog}
              </button>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default FaqTabHeader;
