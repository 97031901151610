export const BlogTypes = [
  "All",
  "Resort",
  "Hotel",
  "Home Stay",
  "Temple",
  "Beach",
  "Mountain",
  "Contact",
  "Blog",
];
